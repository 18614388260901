/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {ButtonToolbar, Button} from 'react-bootstrap';
import {StyledModalBackdrop, StyledModalDialog, StyledModal, StyledMobileModal} from '../../../../../styled/ModalStyled';
import {StyledCloseModal, StyledModalBody, StyledModalHeader} from '../../../../Settings/components/modals/ModalStyled';
import InputTextCell from "../../../../RequestDetails/components/editWorkOrderStepsTable/InputTextCell";
import SelectableCell from "../../../../Settings/components/qcProfiles/templateBuilder/table/SelectableCell";
import InputNumberCell from "../../../../RequestDetails/components/editWorkOrderStepsTable/InputNumberCell";
import TextEditableField from "../../../../RequestDetails/components/forms/TextEditableField";

const EditProjectGroupStepsModal = (props) => {
    const {
        productCodes,
        onClose,
        onSave,
    } = props;

    // deep copy
    let itemizedList = props.itemizedList.map((item, idx) => {
        const formatCostsList = item.formatCostsList.map(cost => {
            const stepData = {...cost.stepData};
            return {...cost, stepData: stepData}
        });
        return {
            ...item,
            formatIdx: idx,
            formatCostsList
        }
    });

    // add deep copy
    const [formatCostSteps, setFormatCostSteps] = useState(itemizedList);

    const headerSet = new Set();
    formatCostSteps.forEach(f => f.formatCostsList.forEach(c => headerSet.add(c.headerName)));

    useEffect(() => {
        document.body.classList.add('modal-open');
        return () => {
            document.body.classList.remove('modal-open');
        };
    }, []);

    const handleChange = (formatIndex, stepIndex, name, value) => {
        const newFormatCostSteps = [...formatCostSteps];
        const costStep = newFormatCostSteps[formatIndex].formatCostsList[stepIndex];

        if (name === 'productCode') {
            costStep.stepData.ProductCode = value.name;
            costStep.stepData.Unit = value.Unit;
            costStep.stepData.OverrideRate = value.Rate;
            costStep.stepData.StockDescription = value.StockDescription;
            costStep.description = value.StockDescription;
        }
        if (name === 'unitPrice') {
            costStep.stepData.OverrideRate = value;
        }
        if (name === 'stock_description') {
            costStep.stepData.StockDescription = value;
            costStep.description = value;
        }
        if (name === 'label') {
            costStep.stepData.Label = value;
            costStep.stepData.StepSubtypeLabel = value;
        }
        if (name === 'description') {
            costStep.stepData.Details = value;
        }
        if (name === 'header') {
            costStep.headerName = value;
        }

        setFormatCostSteps(newFormatCostSteps);
    }

    const handleAddNewStep = (formatIndex) => {
      const newFormatCostSteps = [...formatCostSteps];
      const numOfEpisodes = newFormatCostSteps[formatIndex].formatCostsList[0].quantity;
      const headerName = newFormatCostSteps[formatIndex].formatCostsList[0].headerName;
      newFormatCostSteps[formatIndex].formatCostsList.push({
        isBillable: true,
        description: 'new step description',
        productCode: null,
        quantity: numOfEpisodes,
        headerName: headerName,
        stepData: {
          Details: 'new step description',
          Cost: 0,
          Flags: 0,
          IsBillable: true,
          Label: 'new step label',
          StepSubtypeLabel: 'new step label',
          OverrideRate: 0,
          ProductCode: null,
          Quantity: 1,
          Rate: 0,
          StepFlags: 0,
          Unit: null,
          unitPrice: 0
        }
      });
      setFormatCostSteps(newFormatCostSteps);
    }

    const handleDeleteStep = (formatIndex, stepIndex) => {
        const newFormatCostSteps = [...formatCostSteps];
        newFormatCostSteps[formatIndex].formatCostsList.splice(stepIndex, 1);
        setFormatCostSteps(newFormatCostSteps);
    }

    const handleChangeQcTypeText = (oldText, text) => {
      const newFormatCostSteps = [...formatCostSteps];
      newFormatCostSteps.filter(f => f.formatCostsList.find(x => x.headerName === oldText) != null).forEach(f => {
          f.formatCostsList.filter(x => x.headerName === oldText).forEach(c => c.headerName = text);
      });
      setFormatCostSteps(newFormatCostSteps);
    }

    const handleClose = () => {
        onClose();
    };

    const handleSave = () => {
        onSave(formatCostSteps);
    };

    let rowNum = 1;

    return (
        <StyledMobileModal>
            <StyledModalBackdrop onClick={handleClose}/>
            <StyledModalDialog className="modal-dialog-custom">
                <StyledModalCustom className="modal-styled-custom">
                    <StyledModalHeader className="modal-header-custom">
                        Edit cost steps
                        <StyledCloseModal className="icon-close-modal" title="Close" onClick={handleClose}/>
                    </StyledModalHeader>
                    <StyledModalBodyCustom className="modal-body-custom edit-record-modal-body no-scroll">
                        <div className="wrap-content">
                          <div>You can edit the values for the description, product code and unit price columns.<br/>The values for the line total column will be calculated automatically using the formula: unit price * quantity.</div>
                          <div className="table-wrapper">
                            <table className="table">
                                <thead>
                                <tr>
                                    <th className="item-num">
                                        #
                                    </th>
                                    <th className="qc-type">
                                        Group Header
                                    </th>
                                    <th className="qc-type">
                                        QC Type
                                    </th>
                                    <th className="quantity">
                                        Quantity
                                    </th>
                                    <th className="step-label">
                                        Label
                                    </th>
                                    <th className="details">
                                        Details
                                    </th>
                                    <th className="description">
                                        Stock Description
                                    </th>
                                    <th className="product-code">
                                        Product Code
                                    </th>
                                    <th className="unit">
                                        Unit
                                    </th>
                                    <th className="rate">
                                        Rate
                                    </th>
                                    <th className="line-total">
                                    </th>
                                </tr>
                                </thead>
                                <tbody className="cost-items">
                                {Array.from(headerSet).map((qcTypeText, qcTypeIdx) => (
                                  <>
                                  <tr key={`qc-type-${qcTypeIdx}`}>
                                    <td className="qc-type-header" colSpan={10}>
                                        <TextEditableField
                                            value={qcTypeText}
                                            onConfirm={(label, name, newValue, callback) => {
                                                handleChangeQcTypeText(qcTypeText, newValue);
                                                callback();
                                            }}
                                            hiddenHint
                                        />
                                    </td>
                                    <td className="line-total"/>
                                  </tr>
                                  {formatCostSteps.map(({formatName, formatCostsList, formatIdx}) => formatCostsList.find(x => x.headerName === qcTypeText) ? (
                                    <>
                                    {formatCostsList.map((costItem, cIdx) => costItem.isBillable && costItem.headerName === qcTypeText ? (
                                    <tr key={`f-${formatIdx}-${cIdx}`}>
                                        <td className="item-num">
                                            <div>{rowNum++}</div>
                                        </td>
                                        <td className="step-header">
                                          <TextEditableField
                                            value={costItem.headerName}
                                            onConfirm={(label, name, newValue, callback) => {
                                                handleChange(formatIdx, cIdx, 'header', newValue);
                                                callback();
                                            }}
                                            hiddenHint
                                            hideActionButtons
                                          />
                                        </td>
                                        <td className="qc-type">
                                            <div>{formatName}</div>
                                        </td>
                                        <td className="quantity">
                                            <div>{costItem.quantity.toFixed(2)}</div>
                                        </td>
                                        <InputTextCell
                                            className="step-label"
                                            placeholder="Enter Step Label..."
                                            value={costItem.stepData.Label}
                                            onChange={t => handleChange(formatIdx, cIdx, 'label', t)}
                                            onFocus={() => {}}
                                            onBlur={() => {}}
                                            maxLength={150}
                                            minWidth={350}
                                        />
                                        <InputTextCell
                                            className="step-details"
                                            placeholder="Enter Step Details..."
                                            value={costItem.stepData.Details}
                                            onChange={t => handleChange(formatIdx, cIdx, 'description', t)}
                                            onFocus={() => {}}
                                            onBlur={() => {}}
                                            maxLength={150}
                                            minWidth={350}
                                        />
                                        <InputTextCell
                                            className="step-stock-description"
                                            placeholder="Enter Stock Description..."
                                            value={costItem.stepData.StockDescription}
                                            onChange={t => handleChange(formatIdx, cIdx, 'stock_description', t)}
                                            onFocus={() => {}}
                                            onBlur={() => {}}
                                            maxLength={150}
                                            minWidth={350}
                                        />
                                        <SelectableCell
                                            value={costItem.stepData.ProductCode}
                                            options={productCodes || []}
                                            onChange={o => handleChange(formatIdx, cIdx, 'productCode', o)}
                                            onFocus={() => {}}
                                            onBlur={() => {}}
                                            isProductCode
                                            allProductCodes={productCodes || []}
                                            menuMaxHeight={150}
                                            minWidth={225}
                                            menuPortalTarget={true}
                                            menuPlacement="auto"
                                        />
                                        <td className="unit">
                                            <div>{costItem.stepData.Unit}</div>
                                        </td>
                                        <InputNumberCell
                                            value={costItem.stepData.OverrideRate}
                                            onChange={v => handleChange(formatIdx, cIdx, 'unitPrice', v)}
                                            onFocus={() => {}}
                                            onBlur={() => {}}
                                            isCost={true}
                                        />
                                        <td className="actions">
                                            <div>
                                                <button onClick={() => handleDeleteStep(formatIdx, cIdx)}>Delete</button>
                                            </div>
                                        </td>
                                    </tr>
                                    ) : null )}
                                    <tr key={formatIdx}>
                                      <td className="new-step" colSpan={10}>
                                        <button onClick={() => handleAddNewStep(formatIdx)}>{formatName} - Add New Step</button>
                                      </td>
                                      <td className="line-total"/>
                                    </tr>
                                    </>
                                  ) : null )}
                                  </>
                                ))}
                                </tbody>
                            </table>
                          </div>
                        </div>
                        <ButtonToolbar>
                            <Button
                                className="btn-in-modal btn-save"
                                onClick={handleSave}
                                title="Save"
                            >
                                Save
                            </Button>
                            <Button
                                className="btn-in-modal btn-cancel"
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </ButtonToolbar>
                    </StyledModalBodyCustom>
                </StyledModalCustom>
            </StyledModalDialog>
        </StyledMobileModal>
    );
};

const StyledModalCustom = styled(StyledModal)`
  .loader-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255, 0.7);
  }
`;

const StyledModalBodyCustom = styled(StyledModalBody)`
  overflow-y: hidden;

  &.no-scroll {
    overflow: hidden !important;
  }

  @media (min-width: 768px) {
    .btn-toolbar {
      position: fixed;
      left: 10%;
      right: 17px;
      bottom: 0;
      margin: 0;
      padding: 20px 0 20px 57px;
      background: #fff;
    }
  }

  @media (max-width: 767px) {
    padding: 5px 0 55px 0 !important;
  }

  .btn {
    padding: 0 20px !important;
    &[disabled] {
      background: #e0e0e1;
      color: #c8c8c8;
      /*cursor: default;*/
    }
    &.btn-save {
      border: #5b5b65 1px solid !important;
      &[disabled] {
        border-color: #ccc !important;
      }
      @media (max-width: 767px) {
        border: none !important;
      }
    }
  }

  .wrap-content {
    @media (min-width: 768px) {
      margin-bottom: 70px;
      min-height: 100%;
      overflow-y: hidden !important;
      > div {
        overflow-y: auto !important;
        max-height: calc(100vh - 89px - 80px - 30px - 70px) !important;
        .table {
          tr {
            th {
              position: sticky;
              top: 0;
              background-color: #fff;
              z-index: 2;
              border-bottom: 0;
              text-align: left;
            }
          }
          tbody {
            tr {
              &:last-child td {
                border-bottom: none !important;
              }
              td > div {
                align-items: center;
                display: flex;
                min-height: 30px;
              }
            }
          }
        }
      }
    }
    @media (max-width: 767px) {
      padding: 20px 25px !important;
      height: 100%;
      max-width: 85vw;
      max-height: calc(40vh + 40px) !important;
      -webkit-overflow-scrolling: touch;
    }

    .editable-control {
      @media (min-width: 992px) {
        width: 100%;
      }
    }

    .form-group {
      margin: 0;
    }
    
    td.qc-type-header {
      border-top: 2px solid #aaa;
    }
  }
`;

export default EditProjectGroupStepsModal;
