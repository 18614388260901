import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import styled, {createGlobalStyle} from 'styled-components';
import qs from 'qs';
import {Grid, Row, Col} from 'react-bootstrap';
import {getReports, changeMenuItem, getReportTable, upload} from './actions';
import PageComponent from '../../components/PageComponent';
import AppLayout from '../../layouts/AppLayout';
import Top from './components/Top';
import Menu from './components/Menu';
import Filters from './components/Filters';
import Table from './components/Table';
import {getMaxOperatorUtilizedDate, getMaxOnTimeMetricsDate} from './components/Filter';
import moment from 'moment';

class Analytics extends PageComponent {
  state = {
    usedFilters: {},
    tableSort: {}
  };

  prepareUsedFilters = usedFilters => {
    const {analytics: {activeMenuItem}} = this.props;
    if (!usedFilters) {
      usedFilters = {};
    }
    if (activeMenuItem === 'operator_utilization' && !(usedFilters.operator_utilized_date_range || {}).end) {
      usedFilters.operator_utilized_date_range = {end: moment(getMaxOperatorUtilizedDate(), 'x').toISOString()};
    }
    if (activeMenuItem === 'on_time_metrics' && !(usedFilters.on_time_metrics_date_range || {}).end) {
      usedFilters.on_time_metrics_date_range = {end: moment(getMaxOnTimeMetricsDate(), 'x').toISOString()};
    }
    return usedFilters;
  };

  componentDidMount() {
    const {dispatch, urlFilters, history} = this.props;
    dispatch(getReports(history, urlFilters)).then(() => {
      const {analytics} = this.props;
      this.setState({
        usedFilters: this.prepareUsedFilters({...analytics.usedFilters})
      });

      if (analytics.usedFilters && Object.keys(analytics.usedFilters).length) {
        this.applyFilters();
      }
    });
    if (urlFilters.sort) {
      this.sortTable(urlFilters.sort);
    }
  }

  changeMenuItem = event => {
    const {dispatch, analytics, history} = this.props;
    if (event.target.id !== analytics.activeMenuItem) {
      const newActiveMenuItem = event.target.id;
      this.resetFilters();
      dispatch(changeMenuItem(newActiveMenuItem))
        .then(res => {
          dispatch(getReportTable(newActiveMenuItem, {}, history, false, res.report.filters));
          this.resetFilters();
        });
    }
  };

  onChange = (id, value) => {
    const usedFilters = {...this.state.usedFilters};
    if (id === "operator_utilized_date_range" || id === 'monthly_netflix_tasks_date_range') {
      if (value.start) {
        value.start = moment(value.start).format('YYYY-MM-DD');
      }
      if (value.end) {
        value.end = moment(value.end).format('YYYY-MM-DD');
      }
    }
    usedFilters[id] = value;
    this.setState({
      usedFilters
    });
  };

  getCurrentReport() {
    const {activeMenuItem, reports} = this.props.analytics;
    return reports && reports.length &&
      reports.find(item => item.name === activeMenuItem);
  }

  getCurrentHeaders() {
    const currentReport = this.getCurrentReport();
    return (currentReport && currentReport.columns) || [];
  }

  getCurrentFilters() {
    const currentReport = this.getCurrentReport();
    return (currentReport && currentReport.filters) || [];
  }

  applyFilters = () => {
    const {usedFilters} = this.state;
    const {dispatch, analytics, history} = this.props;
    dispatch(getReportTable(analytics.activeMenuItem, usedFilters, history));
    this.sortTable(usedFilters.sort);
  };

  sortTable = sort => {
    this.setState({
      tableSort: {
        field: sort,
        direction: 'asc'
      }
    });
  };

  upload = () => {
    const {usedFilters} = this.state;
    const {dispatch, analytics} = this.props;
    dispatch(upload(analytics.activeMenuItem, usedFilters, 'csv'));
  };

  resetFilters = () => {
    this.setState({
      usedFilters: this.prepareUsedFilters(),
      tableSort: {}
    });
  };

  getActiveMenuItemName() {
    const {reports, activeMenuItem} = this.props.analytics;
    const activeMenu = reports && reports.find(item => item.name === activeMenuItem);
    return activeMenu ? activeMenu.title : '';
  }

  handleLoadMore = () => {
    const {dispatch, history, analytics} = this.props;
    const {filteredReport, activeMenuItem, moreLoading} = analytics;
    const {data, total} = filteredReport;
    if ((data || []).length < total && !moreLoading) {
      const {usedFilters} = this.state;
      dispatch(getReportTable(activeMenuItem, usedFilters, history, true));
      this.sortTable(usedFilters.sort);
    }
  };

  render() {
    const {usedFilters, tableSort} = this.state;
    const {
      activeMenuItem, reports, filteredReport, loading, tableLoading, moreLoading
    } = this.props.analytics;

    let headers = this.getCurrentHeaders();
    if (filteredReport.columns && filteredReport.columns.length > 0) {
      headers = filteredReport.columns.map(c => {
        return {
          name: c,
          label: c
        }
      })
    }

    return (
      <AppLayout
        className="analytics-page"
        title="Analytics"
      >
        <AnalyticsStyled>
          <Top/>
          {!loading && !reports.length ? <Row className="clearfix"><Col className="no-reports">No available reports</Col></Row> :
          <Row className="clearfix">
            <Col md={2} className="col">
              <Menu
                data={reports}
                activeMenuItem={activeMenuItem}
                changeMenuItem={this.changeMenuItem}
                loading={loading}
              />
            </Col>
            <Col md={10} className="col">
              <div className="content">
                <Filters
                  activeMenuItemName={this.getActiveMenuItemName()}
                  loading={loading}
                  usedFilters={usedFilters}
                  headers={this.getCurrentHeaders()}
                  filters={this.getCurrentFilters()}
                  applyFilters={this.applyFilters}
                  sortTable={this.sortTable}
                  upload={this.upload}
                  onChange={this.onChange}
                  resetFilters={this.resetFilters}
                />
                <Table
                  headers={headers}
                  data={filteredReport.data || []}
                  loading={loading || (tableLoading && !moreLoading)}
                  tableSort={tableSort}
                  reportName={activeMenuItem}
                  moreLoading={moreLoading}
                  onLoadMore={this.handleLoadMore}
                  hidden={!filteredReport.data}
                />
              </div>
            </Col>
          </Row>
          }
        </AnalyticsStyled>
        <GlobalStyled/>
      </AppLayout>
    );
  }
}

const HEADER_HEIGHT = '59px';
const TOP_HEIGHT = '64px';

const GlobalStyled = createGlobalStyle`
  .analytics-page > div:first-of-type {
    background-color: #eaeaea;
    min-height: calc(100vh - ${HEADER_HEIGHT});
    @media (min-width: 992px) {
      max-height: calc(100vh - ${HEADER_HEIGHT});
    }
  }
`;

const AnalyticsStyled = styled(Grid)`
  .top {
    height: ${TOP_HEIGHT};
  }
  .content {
    background-color: #f3f3f3;
    min-height: calc(100vh - ${HEADER_HEIGHT} - ${TOP_HEIGHT});
    @media (min-width: 992px) {
      max-height: calc(100vh - ${HEADER_HEIGHT} - ${TOP_HEIGHT});
    }
  }

  .col {
    padding: 0;
  }

  .loader-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 3;

    .react-spinner {
      top: 49%;
    }
  }

  .no-reports {
    padding: 15px;
    text-align: center;
    opacity: 0.65;
  }
`;

Analytics.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    analytics: state.analytics,
    urlFilters: qs.parse(ownProps.location.search.substr(1))
  };
}

export default connect(
  mapStateToProps
)(Analytics);
